import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/layout';
import { createProject } from '../../api/projectService';

const CreateProject = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    status: '',
    specifications: '',
    client: '',
    plateforme: '',
    date_creation: '',
    date_suspension: ''
  });

  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newProject = await createProject(formData);
      setMessage('Projet créé avec succès');
      setTimeout(() => {
        navigate('/dashboard'); // Rediriger vers le tableau de bord après 2 secondes
      }, 2000);
    } catch (error) {
      console.error('Erreur lors de la création du projet:', error.message);
      setMessage('Erreur lors de la création du projet');
    }
  };

  return (
    <Layout>
      <div className="container mt-4">
        <h2>Créer un Nouveau Projet</h2>
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Nom du Projet:</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description:</label>
            <textarea className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="status" className="form-label">Statut:</label>
            <input type="text" className="form-control" id="status" name="status" value={formData.status} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="specifications" className="form-label">Spécifications:</label>
            <textarea className="form-control" id="specifications" name="specifications" value={formData.specifications} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Client du Projet:</label>
            <input type="text" className="form-control" id="client" name="client" value={formData.client} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Plateforme:</label>
            <input type="text" className="form-control" id="plateforme" name="plateforme" value={formData.plateforme} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Date de création du Projet:</label>
            <input type="date" className="form-control" id="date_creation" name="date_creation" value={formData.date_creation} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Date de suspension:</label>
            <input type="date" className="form-control" id="date_suspension" name="date_suspension" value={formData.date_suspension} onChange={handleChange} />
          </div>
          <button type="submit" className="btn btn-primary">Créer Projet</button>
        </form>
        {message && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CreateProject;
