import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const API_URL = 'http://37.187.37.169:8083/api'; // Adapter selon votre URL backend

// Action asynchrone pour récupérer tous les projets
export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
  const response = await axios.get(`${API_URL}/projects`);
  return response.data;
});

// Action asynchrone pour créer un nouveau projet
export const createProject = createAsyncThunk('projects/createProject', async (projectData) => {
  const response = await axios.post(`${API_URL}/projects`, projectData);
  return response.data;
});

// Action asynchrone pour mettre à jour un projet existant
export const updateProject = createAsyncThunk('projects/updateProject', async ({ id, projectData }) => {
  const response = await axios.put(`${API_URL}/projects/${id}`, projectData);
  return response.data;
});

// Action asynchrone pour supprimer un projet existant
export const deleteProject = createAsyncThunk('projects/deleteProject', async (id) => {
  await axios.delete(`${API_URL}/projects/${id}`);
  return id;
});

const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects.push(action.payload);
      })
      .addCase(createProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = state.projects.map(project =>
          project.id === action.payload.id ? action.payload : project
        );
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = state.projects.filter(project => project.id !== action.payload);
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default projectSlice.reducer;
