import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentations, deleteDocumentation } from '../../redux/slices/documentationSlice';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../components/layout';

const DocumentationList = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const documentations = useSelector((state) => state.documentation.documentations);
  const status = useSelector((state) => state.documentation.status);

  useEffect(() => {
    dispatch(fetchDocumentations(projectId));
  }, [dispatch, projectId]);

  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette documentation ?")) {
      try {
        await dispatch(deleteDocumentation(id)).unwrap();
        // Remove the deleted documentation from the local state
        dispatch(fetchDocumentations(projectId));
      } catch (error) {
        console.error("Erreur lors de la suppression de la documentation:", error);
      }
    }
  };

  return (
    <Layout>
      <div className="container mt-4">
        <h2>Liste des Documentations</h2>
        {status === 'loading' ? (
          <p>Chargement...</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Titre du Sous-Projet</th>
                <th>Type de Documentation</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {documentations.map((doc) => (
                <tr key={doc.id}>
                  <td>{doc.subprojecttitle}</td>
                  <td>{doc.title}</td>
                  <td>
                    <Link to={`/projects/${projectId}/documentation/edit/${doc.id}`} className="btn btn-primary btn-sm me-2">Modifier</Link>
                    <button onClick={() => handleDelete(doc.id)} className="btn btn-danger btn-sm">Supprimer</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Layout>
  );
};

export default DocumentationList;
