import axios from 'axios';

const API_URL = 'http://37.187.37.169:8083/api/documentations';

export const fetchDocumentations = async (projectId) => {
  const response = await axios.get(`${API_URL}/project/${projectId}`);
  return response.data;
};

export const fetchSubprojects = async (projectId) => {
  const response = await axios.get(`${API_URL}/subprojects/${projectId}`);
  return response.data;
};


export const addDocumentation = async (projectId, documentation) => {
  const response = await axios.post(`${API_URL}`, { project_id: projectId, ...documentation });
  return response.data;
};

export const updateDocumentation = async (docId, documentation) => {
  const response = await axios.put(`${API_URL}/${docId}`, documentation);
  return response.data;
};

export const deleteDocumentation = async (docId) => {
  const response = await axios.delete(`${API_URL}/${docId}`);
  return response.data;
};

export const uploadImage = async (formData) => {
  const response = await axios.post(`${API_URL}/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const fetchDocumentationById = async (id) => {
  const response = await axios.get(`${API_URL}/${id}`);
  return response.data;
};
