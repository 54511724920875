// src/redux/slices/fieldSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = 'http://37.187.37.169:8083/api';

export const fetchSections = createAsyncThunk(
  'fields/fetchSections',
  async (documentationId) => {
    const response = await axios.get(`${API_URL}/sections/${documentationId}`);
    return response.data;
  }
);

export const createSection = createAsyncThunk(
  'fields/createSection',
  async ({ documentationId, section }) => {
    const response = await axios.post(`${API_URL}/sections`, { ...section, documentation_id: documentationId });
    return response.data;
  }
);

export const fetchContents = createAsyncThunk(
  'fields/fetchContents',
  async (sectionId) => {
    const response = await axios.get(`${API_URL}/contents/${sectionId}`);
    return response.data;
  }
);

export const createContent = createAsyncThunk(
  'fields/createContent',
  async ({ sectionId, content }) => {
    const response = await axios.post(`${API_URL}/contents`, { ...content, section_id: sectionId });
    return response.data;
  }
);

const fieldSlice = createSlice({
  name: 'fields',
  initialState: {
    sections: [],
    contents: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSections.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sections = action.payload;
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createSection.fulfilled, (state, action) => {
        state.sections.push(action.payload);
      })
      .addCase(fetchContents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contents = action.payload;
      })
      .addCase(fetchContents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createContent.fulfilled, (state, action) => {
        state.contents.push(action.payload);
      });
  },
});

export default fieldSlice.reducer;

