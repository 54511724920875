import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchDocumentationById, updateDocumentation } from '../../api/documentation';
import Layout from '../../components/layout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUploader from '../../components/ImageUploader'; // Assurez-vous que le chemin est correct

const EditDocumentation = () => {
  const { projectId, id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    subprojectTitle: '',
    title: 'Documentation Technique',
    platform: '',
    sections: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const doc = await fetchDocumentationById(id);
        setFormData({
          subprojectTitle: doc.subprojecttitle,
          title: doc.title,
          platform: doc.platform,
          sections: doc.sections,
        });
      } catch (error) {
        console.error('Erreur lors de la récupération de la documentation:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSectionChange = (index, e) => {
    const sections = [...formData.sections];
    sections[index].title = e.target.value;
    setFormData({ ...formData, sections });
  };

  const handleSectionTypeChange = (index, e) => {
    const sections = [...formData.sections];
    const type = e.target.value;
    sections[index].type = type;

    if (type === 'Content') {
      sections[index].content = { editor: '' };
    } else if (type === 'Authentication') {
      sections[index].content = { url: '', user: '', pass: '', protocol: '' };
    } else if (type === 'Link') {
      sections[index].content = { link: '' };
    }

    setFormData({ ...formData, sections });
  };

  const handleContentChange = (sectionIndex, key, value) => {
    const sections = [...formData.sections];
    sections[sectionIndex].content[key] = value;
    setFormData({ ...formData, sections });
  };

  const addSection = () => {
    setFormData({
      ...formData,
      sections: [...formData.sections, { title: '', type: 'Content', content: { editor: '' } }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDocumentation(id, { ...formData, project_id: parseInt(projectId) });
      alert('Documentation mise à jour avec succès !');
      navigate(`/projects/${projectId}/documentation/view`);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la documentation:', error);
      alert('Erreur lors de la mise à jour de la documentation');
    }
  };

  return (
    <Layout>
      <div className="container mt-4">
        <h2>Modifier la Documentation</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="subprojectTitle" className="form-label">Titre du Sous-Projet</label>
            <input
              type="text"
              className="form-control"
              id="subprojectTitle"
              name="subprojectTitle"
              value={formData.subprojectTitle}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Type de Documentation</label>
            <select
              className="form-control"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
            >
              <option value="Documentation Technique">Documentation Technique</option>
              <option value="How to use">How to use</option>
            </select>
          </div>

          {formData.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-3">
              <label htmlFor={`section-${sectionIndex}`} className="form-label">Section {sectionIndex + 1}</label>
              <input
                type="text"
                className="form-control"
                id={`section-${sectionIndex}`}
                value={section.title}
                onChange={(e) => handleSectionChange(sectionIndex, e)}
                required
              />
              <label className="form-label mt-2">Type de Section</label>
              <select
                className="form-control mt-2"
                value={section.type}
                onChange={(e) => handleSectionTypeChange(sectionIndex, e)}
              >
                <option value="Content">Content</option>
                <option value="Authentication">Authentication</option>
                <option value="Link">Link</option>
              </select>
              {section.type === 'Content' && (
                <>
                  <label className="form-label mt-2">Contenu</label>
                  <ReactQuill
                    value={section.content.editor}
                    onChange={(value) => handleContentChange(sectionIndex, 'editor', value)}
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['bold', 'italic', 'underline'],
                        ['link', 'image'],
                      ],
                    }}
                    formats={[
                      'header', 'font', 'list', 'bold', 'italic', 'underline', 'link', 'image',
                    ]}
                  />
                </>
              )}
              {section.type === 'Authentication' && (
                <>
                  <label className="form-label mt-2">URL</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.url}
                    onChange={(e) => handleContentChange(sectionIndex, 'url', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">User</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.user}
                    onChange={(e) => handleContentChange(sectionIndex, 'user', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={section.content.pass}
                    onChange={(e) => handleContentChange(sectionIndex, 'pass', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">Protocol</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.protocol}
                    onChange={(e) => handleContentChange(sectionIndex, 'protocol', e.target.value)}
                    required
                  />
                </>
              )}
              {section.type === 'Link' && (
                <>
                  <label className="form-label mt-2">Link</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.link}
                    onChange={(e) => handleContentChange(sectionIndex, 'link', e.target.value)}
                    required
                  />
                </>
              )}
            </div>
          ))}
          <button type="button" className="btn btn-secondary" onClick={addSection}>Ajouter une section</button>
          <button type="submit" className="btn btn-primary">Mettre à jour la Documentation</button>
        </form>
      </div>
    </Layout>
  );
};

export default EditDocumentation;
