import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addDocumentation } from '../../redux/slices/documentationSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/layout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateDocumentation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [documentation, setDocumentation] = useState({
    subprojectTitle: '',
    title: 'Documentation Technique',
    platform: '',
    sections: [],
  });

  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentation({ ...documentation, [name]: value });
  };

  const addSection = () => {
    setDocumentation({
      ...documentation,
      sections: [...documentation.sections, { title: '', type: 'Content', content: { editor: '' } }],
    });
  };

  const handleSectionChange = (index, e) => {
    const sections = [...documentation.sections];
    sections[index].title = e.target.value;
    setDocumentation({ ...documentation, sections });
  };

  const handleSectionTypeChange = (index, e) => {
    const sections = [...documentation.sections];
    const type = e.target.value;
    sections[index].type = type;

    if (type === 'Content') {
      sections[index].content = { editor: '' };
    } else if (type === 'Authentication') {
      sections[index].content = { url: '', user: '', pass: '', protocol: '' };
    } else if (type === 'Link') {
      sections[index].content = { link: '' };
    }

    setDocumentation({ ...documentation, sections });
  };

  const handleContentChange = (sectionIndex, key, value) => {
    const sections = [...documentation.sections];
    sections[sectionIndex].content[key] = value;
    setDocumentation({ ...documentation, sections });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addDocumentation({ projectId, documentation })).unwrap();
      navigate(`/projects/${projectId}/documentation/view`);
    } catch (error) {
      console.error("Erreur lors de la création de la documentation:", error);
      setError('Erreur lors de la création de la documentation');
    }
  };

  return (
    <Layout>
      <div className="container mt-4">
        <h2>Créer une Documentation</h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="subprojectTitle" className="form-label">Titre du Sous-Projet</label>
            <input
              type="text"
              className="form-control"
              id="subprojectTitle"
              name="subprojectTitle"
              value={documentation.subprojectTitle}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Type de Documentation</label>
            <select className="form-control" id="title" name="title" value={documentation.title} onChange={handleInputChange}>
              <option value="Documentation Technique">Documentation Technique</option>
              <option value="How to use">How to use</option>
            </select>
          </div>
          {documentation.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-3">
              <label htmlFor={`section-${sectionIndex}`} className="form-label">Section {sectionIndex + 1}</label>
              <input
                type="text"
                className="form-control"
                id={`section-${sectionIndex}`}
                value={section.title}
                onChange={(e) => handleSectionChange(sectionIndex, e)}
                required
              />
              <label className="form-label mt-2">Type de Section</label>
              <select
                className="form-control"
                value={section.type}
                onChange={(e) => handleSectionTypeChange(sectionIndex, e)}
              >
                <option value="Content">Content</option>
                <option value="Authentication">Authentication</option>
                <option value="Link">Link</option>
              </select>
              {section.type === 'Content' && (
                <>
                  <label className="form-label mt-2">Contenu</label>
                  <ReactQuill
                    value={section.content.editor}
                    onChange={(value) => handleContentChange(sectionIndex, 'editor', value)}
                    modules={{
                      toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['bold', 'italic', 'underline'],
                        ['link', 'image'],
                      ],
                    }}
                    formats={[
                      'header', 'font', 'list', 'bold', 'italic', 'underline', 'link', 'image',
                    ]}
                  />
                </>
              )}
              {section.type === 'Authentication' && (
                <>
                  <label className="form-label mt-2">URL</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.url}
                    onChange={(e) => handleContentChange(sectionIndex, 'url', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">User</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.user}
                    onChange={(e) => handleContentChange(sectionIndex, 'user', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={section.content.pass}
                    onChange={(e) => handleContentChange(sectionIndex, 'pass', e.target.value)}
                    required
                  />
                  <label className="form-label mt-2">Protocol</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.protocol}
                    onChange={(e) => handleContentChange(sectionIndex, 'protocol', e.target.value)}
                    required
                  />
                </>
              )}
              {section.type === 'Link' && (
                <>
                  <label className="form-label mt-2">Link</label>
                  <input
                    type="text"
                    className="form-control"
                    value={section.content.link}
                    onChange={(e) => handleContentChange(sectionIndex, 'link', e.target.value)}
                    required
                  />
                </>
              )}
            </div>
          ))}
          <button type="button" className="btn btn-secondary" onClick={addSection}>Ajouter une section</button>
          <button type="submit" className="btn btn-primary">Créer la Documentation</button>
        </form>
      </div>
    </Layout>
  );
};

export default CreateDocumentation;
