// src/App.js
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Home from './pages/home';
import Register from './pages/register';
import Login from './pages/login';
import CreateProject from './pages/project/createProject';
import EditProject from './pages/project/editProject';
import ListProjects from './pages/project/ListProjects';
import ProjectDocumentation from './pages/doc/ProjectDocumentation';
import CreateDocumentation from './pages/doc/CreateDocumentation';
import DocumentationChoice from './pages/doc/DocumentationChoice';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import DocumentationList from './pages/doc/DocumentationList';
import EditDocumentation from './pages/doc/EditDocumentation';


const PrivateRoutes = () => {
  const { isAuth } = useSelector(state => state.auth);
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

const RestrictedRoutes = () => {
  const { isAuth } = useSelector(state => state.auth);
  return !isAuth ? <Outlet /> : <Navigate to="/dashboard" />;
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-project" element={<CreateProject />} />
          <Route path="/edit-project/:id" element={<EditProject />} />
          <Route path="/list-projects" element={<ListProjects />} />
          <Route path="/projects/:projectId/documentation" element={<DocumentationChoice />} />
          <Route path="/projects/:projectId/documentation/create" element={<CreateDocumentation />} />
          <Route path="/projects/:projectId/documentation/view" element={<ProjectDocumentation />} />
          <Route path="/projects/:projectId/documentation/list" element={<DocumentationList />} />
          <Route path="/projects/:projectId/documentation/edit/:id" element={<EditDocumentation />} />
        </Route>
        <Route element={<RestrictedRoutes />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
