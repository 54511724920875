import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, deleteProject } from '../../redux/slices/projectSlice';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout';
import ProjectDetailsModal from '../../components/project/ProjectDetailsModal';
import 'bootstrap/dist/css/bootstrap.min.css';

const ListProjects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.projects);
  const loading = useSelector(state => state.projects.loading);
  
  const [modalOpen, setModalOpen] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const openModal = (project) => {
    setProjectDetails(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleDelete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce projet ?")) {
      dispatch(deleteProject(id));
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <Layout>
      <div className="container">
        <h2 className="my-4">Liste des Projets</h2>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Description</th>
                <th scope="col">Statut</th>
                <th scope="col">Spécifications</th>
                <th scope="col">Client</th>
                <th scope="col">Plateforme</th>
                <th scope="col">Date de création</th>
                <th scope="col">Date de suspension</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map(project => (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.description}</td>
                  <td>{project.status}</td>
                  <td>{project.specifications}</td>
                  <td>{project.client}</td>
                  <td>{project.plateforme}</td>
                  <td>{project.date_creation}</td>
                  <td>{project.date_suspension || '-'}</td>
                  <td>
                    <div className="d-flex">
                      <Link to={`/projects/${project.id}/documentation`} className="btn btn-primary me-1">Documentation</Link>
                      <Link to={`/edit-project/${project.id}`} className="btn btn-warning me-1">Modifier</Link>
                      <button onClick={() => handleDelete(project.id)} className="btn btn-danger">Supprimer</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modalOpen && <ProjectDetailsModal project={projectDetails} closeModal={closeModal} />}
    </Layout>
  );
};

export default ListProjects;
