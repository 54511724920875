// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import projectReducer from './slices/projectSlice';
import documentationReducer from './slices/documentationSlice';
import fieldsReducer from './slices/fieldSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectReducer,
    documentation: documentationReducer,
    fields: fieldsReducer,
  },
});

export default store;
