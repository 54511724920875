import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../components/layout';

const DocumentationChoice = () => {
  const { projectId } = useParams();

  return (
    <Layout>
      <div className="container mt-5">
        <h2>Documentation</h2>
        <div className="row mt-4">
          <div className="col">
            <Link to={`/projects/${projectId}/documentation/create`} className="btn btn-success btn-lg">
              Créer une documentation
            </Link>
          </div>
          <div className="col">
            <Link to={`/projects/${projectId}/documentation/view`} className="btn btn-primary btn-lg">
              Voir la documentation
            </Link>
          </div>
          <div className="col">
            <Link to={`/projects/${projectId}/documentation/list`} className="btn btn-warning btn-lg">
              Modifier la documentation
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentationChoice;
