import axios from 'axios'
axios.defaults.withCredentials = true

export async function onRegistration(registrationData) {
  return await axios.post(
    'http://37.187.37.169:8083/api/register',
    registrationData
  )
}

export async function onLogin(loginData) {
  return await axios.post('http://37.187.37.169:8083/api/auth/login', loginData)
  
}

export async function onLogout() {
  return await axios.get('http://37.187.37.169:8083/api/logout')
}

export async function fetchProtectedInfo() {
  return await axios.get('http://37.187.37.169:8083/api/protected')
}
