import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/layout';

const Dashboard = () => {
  return (
    <Layout>
      <div style={styles.container}>
        <h2 style={styles.heading}>Digital DOC's</h2>
        <div style={styles.row}>
          <div style={styles.column}>
            <Link to="/list-projects" style={{ ...styles.button, ...styles.primaryButton }}>
              Lister les projets
            </Link>
          </div>
          <div style={styles.column}>
            <Link to="/create-project" style={{ ...styles.button, ...styles.successButton }}>
              Créer un nouveau projet
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    marginTop: '40px',
    padding: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '2.5rem',
    marginBottom: '30px',
    color: '#333',
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  column: {
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'inline-block',
    padding: '15px 30px',
    fontSize: '1.2rem',
    borderRadius: '50px',
    textDecoration: 'none',
    color: '#fff',
    textAlign: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  primaryButton: {
    backgroundColor: '#007bff',
    '&:hover': {
      backgroundColor: '#0056b3',
      transform: 'scale(1.05)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    },
  },
  successButton: {
    backgroundColor: '#28a745',
    '&:hover': {
      backgroundColor: '#218838',
      transform: 'scale(1.05)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    },
  },
};

export default Dashboard;
