import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = 'http://37.187.37.169:8083/api/documentations';

export const fetchDocumentations = createAsyncThunk(
  'documentation/fetchDocumentations',
  async (projectId) => {
    const response = await axios.get(`${API_URL}/project/${projectId}`);
    return response.data;
  }
);

export const addDocumentation = createAsyncThunk(
  'documentation/addDocumentation',
  async ({ projectId, documentation }) => {
    const response = await axios.post(API_URL, { project_id: projectId, ...documentation });
    return response.data;
  }
);

export const updateDocumentation = createAsyncThunk(
  'documentation/updateDocumentation',
  async ({ id, documentation }) => {
    try {
      const response = await axios.put(`${API_URL}/${id}`, documentation);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la documentation:", error.response?.data || error.message);
      throw error;
    }
  }
);

export const deleteDocumentation = createAsyncThunk(
  'documentation/deleteDocumentation',
  async (id) => {
    await axios.delete(`${API_URL}/${id}`);
    return id;
  }
);

const documentationSlice = createSlice({
  name: 'documentation',
  initialState: {
    documentations: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDocumentations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documentations = action.payload;
      })
      .addCase(fetchDocumentations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDocumentation.fulfilled, (state, action) => {
        state.documentations.push(action.payload);
      })
      .addCase(updateDocumentation.fulfilled, (state, action) => {
        const updatedDocumentation = action.payload;
        const index = state.documentations.findIndex(doc => doc.doc_id === updatedDocumentation.doc_id);
        if (index !== -1) {
          state.documentations[index] = updatedDocumentation;
        }
      })
      .addCase(deleteDocumentation.fulfilled, (state, action) => {
        state.documentations = state.documentations.filter(doc => doc.doc_id !== action.payload);
      });
  },
});

export default documentationSlice.reducer;
