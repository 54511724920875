import axios from 'axios';

const API_URL = 'http://37.187.37.169:8083/api/projects';

// Fonction pour récupérer tous les projets
export const getAllProjects = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour créer un nouveau projet
export const createProject = async (projectData) => {
  try {
    const response = await axios.post(API_URL, projectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour mettre à jour un projet existant
export const updateProject = async (projectId, projectData) => {
  try {
    const response = await axios.put(`${API_URL}/${projectId}`, projectData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fonction pour supprimer un projet
export const deleteProject = async (projectId) => {
  try {
    const response = await axios.delete(`${API_URL}/${projectId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/projects`);
      return response.data; // Assurez-vous que votre API renvoie les projets sous forme de tableau de données
    } catch (error) {
      throw new Error('Error fetching projects:', error);
    }
  };
  

