import React from 'react';
import Layout from '../components/layout';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { FaPlusCircle, FaEdit, FaEye } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  return (
    <Layout>
      <Container className="mt-5">
        <Row className="text-center mb-5">
          <Col>
            <Card className="bg-primary text-white border-0 shadow-lg">
              <Card.Body className="p-5">
                <Card.Title as="h1" className="display-4 fw-bold">Bienvenue sur le Guide et Documentation</Card.Title>
                <Card.Text className="lead">Créez, gérez et consultez la documentation de vos applications facilement.</Card.Text>
                <Button variant="light" size="lg" className="mt-3">Commencer</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="text-center mb-5">
          <Col>
            <h2 className="mb-4 text-primary">Pourquoi utiliser cette application ?</h2>
            <p className="lead text-primary">
              Centralisez et gérez efficacement toutes les documentations de vos applications. Digitaldoc offre une plateforme intuitive pour documenter les fonctionnalités, les API, et les configurations.
            </p>
            <p className="lead text-primary">
              Créez, modifiez, et consultez vos documentations en toute simplicité grâce à notre interface conviviale.
            </p>
          </Col>
        </Row>

        <Row className="text-center">
          <Col md={4} className="mb-4">
            <Card className="shadow-lg h-100">
              <Card.Body className="bg-light">
                <FaPlusCircle size={60} className="text-primary mb-3" />
                <Card.Title className="text-primary">Créer</Card.Title>
                <Card.Text className="text-primary">Ajoutez facilement de nouvelles documentations pour vos projets.</Card.Text>
                <Button variant="primary" className="mt-2">En savoir plus</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="shadow-lg h-100">
              <Card.Body className="bg-light">
                <FaEdit size={60} className="text-primary mb-3" />
                <Card.Title className="text-primary">Gérer</Card.Title>
                <Card.Text className="text-primary">Modifiez et organisez vos documentations existantes avec facilité.</Card.Text>
                <Button variant="primary" className="mt-2">En savoir plus</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="shadow-lg h-100">
              <Card.Body className="bg-light">
                <FaEye size={60} className="text-primary mb-3" />
                <Card.Title className="text-primary">Consulter</Card.Title>
                <Card.Text className="text-primary">Accédez rapidement à toutes vos documentations, où que vous soyez.</Card.Text>
                <Button variant="primary" className="mt-2">En savoir plus</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
