import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentations } from '../../redux/slices/documentationSlice';
import Layout from '../../components/layout';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ProjectDocumentation = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const documentations = useSelector((state) => state.documentation.documentations);
  const [selectedDocumentation, setSelectedDocumentation] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    dispatch(fetchDocumentations(projectId)).then((response) => {
      console.log('Documentations fetched:', response);
    }).catch((error) => {
      console.error('Error fetching documentations:', error);
    });
  }, [dispatch, projectId]);

  useEffect(() => {
    if (selectedDocumentation && selectedDocumentation.sections.length > 0) {
      setSelectedSection(selectedDocumentation.sections[0]);
    }
  }, [selectedDocumentation]);

  const handleDocumentationClick = (documentation) => {
    if (selectedDocumentation?.id === documentation.id) {
      setSelectedDocumentation(null);
    } else {
      setSelectedDocumentation(documentation);
    }
  };

  const groupedDocumentations = documentations.reduce((acc, doc) => {
    const { subprojecttitle } = doc;
    if (!acc[subprojecttitle]) {
      acc[subprojecttitle] = [];
    }
    acc[subprojecttitle].push(doc);
    return acc;
  }, {});

  const renderContent = (section) => {
    switch (section.type) {
      case 'Content':
        return (
          <div style={styles.contentItem}>
            <div dangerouslySetInnerHTML={{ __html: section.content.editor }} />
          </div>
        );
      case 'Authentication':
        return (
          <div style={styles.contentItem}>
            <p><strong>URL:</strong> {section.content.url}</p>
            <p><strong>User:</strong> {section.content.user}</p>
            <p><strong>Password:</strong> {section.content.pass}</p>
            <p><strong>Protocol:</strong> {section.content.protocol}</p>
          </div>
        );
      case 'Link':
        return (
          <div style={styles.contentItem}>
            <p><strong>Link:</strong> <a href={section.content.link} target="_blank" rel="noopener noreferrer">{section.content.link}</a></p>
          </div>
        );
      default:
        return <p>Type de section non reconnu</p>;
    }
  };

  return (
    <Layout>
      <div style={styles.container}>
        <div style={styles.sidebar}>
          <input type="text" placeholder="Search..." style={styles.searchInput} />
          {Object.keys(groupedDocumentations).map((subprojectTitle) => (
            <div key={subprojectTitle}>
              <div style={styles.subprojectTitle}>{subprojectTitle}</div>
              {groupedDocumentations[subprojectTitle].map((documentation) => (
                <div key={documentation.id}>
                  <div style={styles.documentationTitle} onClick={() => handleDocumentationClick(documentation)}>
                    {documentation.title}
                  </div>
                  {selectedDocumentation?.id === documentation.id && (
                    <ul style={styles.linkList}>
                      {documentation.sections.map((section, index) => (
                        <li key={index} style={styles.linkItem} onClick={() => setSelectedSection(section)}>
                          {section.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div style={styles.content}>
          {selectedSection ? (
            <>
              <h2>{selectedSection.title}</h2>
              <div style={styles.contentList}>
                {renderContent(selectedSection)}
              </div>
            </>
          ) : (
            <p>Sélectionnez une section pour afficher son contenu.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    display: 'flex',
  },
  sidebar: {
    height: '100vh',
    width: '250px',
    backgroundColor: '#f8f9fa',
    borderRight: '1px solid #dee2e6',
    position: 'fixed',
    padding: '10px 0',
    left: 0,
    top: '60px',
    overflowY: 'auto',
  },
  searchInput: {
    width: '90%',
    margin: '10px auto',
    padding: '10px',
    display: 'block',
    borderRadius: '4px',
    border: '1px solid #ced4da',
  },
  subprojectTitle: {
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#343a40',
    borderBottom: '1px solid #dee2e6',
  },
  documentationTitle: {
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#0080C2',
    cursor: 'pointer',
    borderBottom: '1px solid #dee2e6',
  },
  linkList: {
    listStyle: 'none',
    padding: 0,
  },
  linkItem: {
    padding: '5px 20px',
    cursor: 'pointer',
    color: '#039AE8',
  },
  content: {
    marginLeft: '270px',
    padding: '20px',
    flexGrow: 1,
  },
  contentList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  contentItem: {
    backgroundColor: '#ffffff',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
  },
};

export default ProjectDocumentation;
