import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ProjectDetailsModal = ({ project, closeModal }) => {
  if (!project) return null;

  return (
    <Modal show={true} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Détails du Projet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Nom:</strong> {project.name}</p>
        <p><strong>Description:</strong> {project.description}</p>
        <p><strong>Statut:</strong> {project.status}</p>
        <p><strong>Spécifications:</strong> {project.specifications}</p>
        <p><strong>Client:</strong> {project.client}</p>
        <p><strong>Plateforme:</strong> {project.plateforme}</p>
        <p><strong>Date de création:</strong> {project.date_creation}</p>
        <p><strong>Date de suspension:</strong> {project.date_suspension || '-'}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectDetailsModal;
