import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateProject } from '../../redux/slices/projectSlice';
import Layout from '../../components/layout';

const EditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector(state =>
    state.projects.projects.find(project => project.id === parseInt(id))
  );

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [specifications, setSpecifications] = useState('');
  const [client, setClient] = useState('');
  const [plateforme, setPlateforme] = useState('');
  const [dateCreation, setDateCreation] = useState('');
  const [dateSuspension, setDateSuspension] = useState('');

  useEffect(() => {
    if (project) {
      setName(project.name);
      setDescription(project.description);
      setStatus(project.status);
      setSpecifications(project.specifications);
      setClient(project.client);
      setPlateforme(project.plateforme);
      setDateCreation(project.date_creation);
      setDateSuspension(project.date_suspension);
    }
  }, [project]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(updateProject({
      id: project.id,
      projectData: {
        name,
        description,
        status,
        specifications,
        client,
        plateforme,
        date_creation: dateCreation, // Assurez-vous que le nom de la propriété correspond au nom dans la base de données
        date_suspension: dateSuspension // Assurez-vous que le nom de la propriété correspond au nom dans la base de données
      }
    }));
    navigate('/list-projects');
  };

  if (!project) return <div>Project not found</div>;

  return (
    <Layout>
      <div className="container mt-4">
        <h2>Modification du projet</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Nom</label>
            <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <input type="text" className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="status" className="form-label">Status</label>
            <input type="text" className="form-control" id="status" value={status} onChange={(e) => setStatus(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="specifications" className="form-label">Specifications</label>
            <input type="text" className="form-control" id="specifications" value={specifications} onChange={(e) => setSpecifications(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="client" className="form-label">Client du Projet</label>
            <input type="text" className="form-control" id="client" value={client} onChange={(e) => setClient(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="plateforme" className="form-label">Plateforme</label>
            <input type="text" className="form-control" id="plateforme" value={plateforme} onChange={(e) => setPlateforme(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="date_creation" className="form-label">Date de création du Projet</label>
            <input type="text" className="form-control" id="date_creation" value={dateCreation} onChange={(e) => setDateCreation(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="date_suspension" className="form-label">Date de suspension</label>
            <input type="text" className="form-control" id="date_suspension" value={dateSuspension} onChange={(e) => setDateSuspension(e.target.value)} />
          </div>
          
          <button type="submit" className="btn btn-success">Enregistrer les changements</button>
        </form>
      </div>
    </Layout>
  );
};

export default EditProject;
